import service from "@/utils/request"

export function queryCustomCategoryPageApi(params) {
  return service({
    url: "/customCategory/queryCustomCategoryPage",
    method: "get",
    params
  })
}

export function addCustomCategoryApi(data) {
  return service({
    url: "/customCategory/addCustomCategory",
    method: "post",
    data
  })

}