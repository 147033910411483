<template>
  <div>
    <form action="#">
      <!--begin::Card-->
      <div class="card mb-7" style="border-top-left-radius: 0px;border-top-right-radius: 0px">
        <!--begin::Card body-->
        <div class="card-body">
          <!--begin::Compact form-->
          <div class="d-flex align-items-center">
            <!--begin::Input group-->
            <div style="display: flex;flex-direction: row">
              <div class="position-relative w-md-400px me-md-2">
                            <span
                                class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="24px" height="24px" viewBox="0 0 24 24">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <path
                                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                            fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                        <path
                                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                            fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                </svg>
                            </span>
                <!--end::Svg Icon-->
                <input type="text" class="form-control form-control-solid ps-10" id="sTitle" name="shopName"
                       value="" placeholder="订单编号" v-model="queryParams.orderNo">
              </div>
              <div class="d-flex align-items-center">
                <div class="btn btn-primary me-5" @click="resetGetAfsList">查询</div>
                <a id="kt_horizontal_search_advanced_link" class="btn btn-link" data-bs-toggle="collapse"
                   href="#kt_advanced_afs_search_form">高级查询</a>
              </div>
            </div>

            <!--end::Input group-->
            <!--begin:Action-->

            <!--end:Action-->
            <div style="margin-right: 10px">
              <!--begin::Actions-->

              <!--end::Actions-->
            </div>
          </div>
          <!--end::Compact form-->
          <!--begin::高级搜索-->
          <div class="collapse" id="kt_advanced_afs_search_form">
            <!--begin::Separator-->
            <div class="separator separator-dashed mt-9 mb-6"></div>
            <!--end::Separator-->
            <!--begin::Row-->
            <div class="row g-8 mb-8">
              <div class="col-lg-3">
                <label class="fs-6 form-label fw-bolder text-dark">服务状态</label>
                <!--begin::Select-->
                <br>
                <el-select v-model="queryParams.status" placeholder="请选择">
                  <el-option value="" label="全部">全部</el-option>
                  <el-option value="10" label="申请中待审核">申请中待审核</el-option>
                  <el-option value="20" label="审核完成待收货">审核完成待收货</el-option>
                  <el-option value="30" label="审核完成待收货">收货完成待处理</el-option>
                  <el-option value="40" label="处理完成（如需退款则等待退款）">处理完成（如需退款则等待退款）</el-option>
                  <el-option value="50" label="待用户确认">待用户确认</el-option>
                  <el-option value="60" label="用户确认完成">用户确认完成</el-option>
                  <el-option value="70" label="取消">取消</el-option>
                  <el-option value="80" label="驳回">驳回</el-option>
                </el-select>
                <!-- <select  v-model="queryParams.shopStatus" class="form-select form-select-solid"
                    data-control="select2" data-placeholder="请选择" data-hide-search="true" id="shopStatus"
                    name="shopStatus">
                    <option value="">请选择</option>
                    <option value="1">使用中</option>
                    <option value="2">已过期</option>
                    <option value="3">已停用</option>

                </select> -->
              </div>

              <!--begin::Col-->
              <div class="col-lg-4">
                <label class="fs-6 form-label fw-bolder text-dark">创建日期区间</label>
                <!-- <input class="form-control form-control-solid" placeholder="选择日期区间" id="shopCreateDate" /> -->
                <br>
                <el-date-picker v-model="createDateRange" type="daterange" align="right" unlink-panels
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                value-format="yyyy-MM-dd" :picker-options="pickerOptions" @change="startDateChange">
                </el-date-picker>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <!--end::Advance form-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </form>

    <div class="tab-content">
      <!--begin::Tab pane-->
      <div id="kt_project_users_card_pane2" class="tab-pane fade active show">
        <!--begin::Card-->
        <div class="card card-flush">
          <!--begin::Card body-->
          <div class="card-body pt-0">
            <!--begin::Table container-->
            <div class="table-responsive tableProduct">
              <!--begin::Table-->
              <el-table :data="rows" empty-text="未查询到数据">
                <template #empty>
                  <p >
                    暂无更多
                  </p>
                </template>
<!--                <el-table-column-->
<!--                    type="selection" width="50">-->
<!--                </el-table-column>-->
                <el-table-column
                    label="商品名称"
                    width="300"
                >
                  <template slot-scope="scope">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50px me-5"> <img :src="scope.row.picUrl"
                                                                 class="" alt=""> </div>
                      <div class="d-flex justify-content-start flex-column cursor-pointer">
                        <a
                           class="text-muted  d-block fs-7  text-hover-primary ">{{ scope.row.skuName }}
                        </a>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    label="订单编号"
                    width="160"
                >
                  <template slot-scope="scope">
                    <a  class="text-dark fw-bolder  d-block mb-1 fs-7">{{ scope.row.orderNo }}</a>
                  </template>
                </el-table-column>

                <el-table-column
                    label="数量"
                    width="80"
                >
                  <template slot-scope="scope">
                    <a  class="text-dark fw-bolder  d-block mb-1 fs-7">{{ scope.row.num }}</a>
                  </template>
                </el-table-column>

                <el-table-column
                    label="类型"
                    width="80"
                >
                  <template slot-scope="scope">
                    <a  class="text-dark fw-bolder  d-block mb-1 fs-7">{{ scope.row.typeDesc }}</a>
                  </template>
                </el-table-column>

                <el-table-column
                    label="服务状态"
                    width="120"
                >
                  <template slot-scope="scope">
                    <a  class="text-dark fw-bolder  d-block mb-1 fs-7">{{ scope.row.statusDesc}}</a>
                    <a class=" fs-7" v-if="scope.row.submitApplyStatus==2">未提交到京东</a>
                  </template>
                </el-table-column>
                <el-table-column
                    label="下单时间"
                >
                  <template slot-scope="scope">
                    <a  class="text-dark fw-bolder  d-block mb-1 fs-7">{{ scope.row.orderTime}}</a>
                  </template>
                </el-table-column>

                <el-table-column
                    label="申请时间"
                >
                  <template slot-scope="scope">
                    <a  class="text-dark fw-bolder  d-block mb-1 fs-7">{{ scope.row.createTime}}</a>
                  </template>
                </el-table-column>

                <el-table-column
                    label="操作"
                >
                  <template slot-scope="scope">
                    <a  class="btn btn-success   btn-sm px-4" data-bs-toggle="modal"
                        data-bs-target="#editProduct2ShopModal"
                        @click="openThisAfsDetail(scope.row)">查看并处理</a>&nbsp;&nbsp;

                  </template>
                </el-table-column>
              </el-table>

              <!--end::Table-->
            </div>
            <!--end::Table container-->
            <!-- 分页按钮 -->
            <el-pagination style="display:flex;justify-content:flex-end;margin-top: 5px" @size-change="handleSizeChange"
                           @current-change="handleCurrentChange" :current-page="queryParams.current" :page-sizes="[10,20,30,50,100]"
                           :page-size="queryParams.size" layout="total, sizes, prev, pager, next, jumper" :total="totalCount"
                           background>
            </el-pagination>
          </div>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>

    <el-dialog title="售后进度"  v-if="afsDetailVisible"  :visible.sync="afsDetailVisible"
               width="40%" appendToBody="true">


        <el-card shadow="always" >
            <div style="display:flex;">
              <div>售后服务单号：</div>
              <div>{{currentDetail.id}}</div>
            </div>
        </el-card>

        <el-card shadow="always">
            <div>商品信息</div>
            <div style="display: flex">
              <div>
                <el-image :src="currentDetail.goodsInfo.picUrl" style="width: 100px;height: 100px;" ></el-image>
              </div>
              <div style="margin-left: 10px;display: flex;flex-direction: column; justify-content: center">
                  <div>
                    {{currentDetail.goodsInfo.skuName}}
                  </div>
                  <div style="display:flex;">
                    <div>数量:</div>
                    <div>{{currentDetail.goodsInfo.num}}</div>
                  </div>
              </div>
            </div>
        </el-card>

      <el-card shadow="always">
        <div  style="display: flex;flex-direction: column">
            <div style="display: flex">
              <div>申请类型：</div>
              <div style="margin-left: 10px">{{currentDetail.typeDesc}}</div>
            </div>
          <div style="display: flex">
            <div>问题描述：</div>
            <div style="margin-left: 10px">{{currentDetail.questionDetail}}</div>
          </div>
          <div style="display: flex">
            <div>问题图片：</div>
            <div style="margin-left: 10px">
              <el-image v-for="(item,index) in currentDetail.imgUrlList"
                        :key="index" :src="item"
                        :preview-src-list="currentDetail.imgUrlList"
                        style="width: 100px;height: 100px;">
              </el-image>
            </div>
          </div>
        </div>
      </el-card>
      <el-card>
        <div style="display: flex">
          <div>服务进度：</div>
          <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            {{ nextStatusDesc }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="currentDetail.goodsInfo.goodsType===1" :disabled="currentDetail.status>5" command="10">提交售后申请到京东</el-dropdown-item>
              <el-dropdown-item v-if="currentDetail.goodsInfo.goodsType!==1" :disabled="currentDetail.status<20 && currentDetail.status>60" command="20">审核完成待收货</el-dropdown-item>
              <el-dropdown-item v-if="currentDetail.goodsInfo.goodsType!==1" :disabled="currentDetail.status<30 && currentDetail.status>60" command="30">收货完成待处理</el-dropdown-item>
              <el-dropdown-item v-if="currentDetail.goodsInfo.goodsType!==1" :disabled="currentDetail.status<30 && currentDetail.status>60" command="40">处理完成（如需退款则等待退款）</el-dropdown-item>
              <el-dropdown-item  :disabled="currentDetail.status>60" command="80">驳回</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

      </el-card>
      <el-card v-if="nextStatus===20">
        <div  style="display:inline-block;">
                            商品寄回地址
                            <el-tooltip effect="dark" content="退回的商品将会寄往此处" placement="bottom">
                                <i class='el-icon-question'/>
                            </el-tooltip>
        </div>
        <el-form style="margin-top: 10px">
        <el-row>
          <el-col :span="24">
            <el-form-item prop="provinceId">
              <el-col :span="5">
                <el-select v-model="currentDetail.goodsReturnProvinceId"  clearable  placeholder="请选择省" filterable @change="provinceChange">
                  <el-option v-for="(item, index) in provinceList" :key="'a'+index" :label="item.name"
                             :value="item.kid"></el-option>
                </el-select>
              </el-col>
              <el-col :span="1">&nbsp;</el-col>
              <el-col :span="5">
                <el-select v-model="currentDetail.goodsReturnCityId" placeholder="请选择市"  filterable @change="cityChange">
                  <el-option v-for="(item, index) in cityList" :key="'b'+index" :label="item.name"
                             :value="item.kid"></el-option>
                </el-select>
              </el-col>
              <el-col :span="1">&nbsp;</el-col>
              <el-col :span="5">
                <el-select v-model="currentDetail.goodsReturnAreaId" placeholder="请选择县"  filterable  @change="areaChange">
                  <el-option v-for="(item, index) in areaList" :key="'c'+index" :label="item.name"
                             :value="item.kid"></el-option>
                </el-select>
              </el-col>
              <el-col :span="1">&nbsp;</el-col>
              <el-col :span="5">
                <el-select v-model="currentDetail.goodsReturnStreetId"  placeholder="请选择街道" >
                  <el-option value="0" label="无"></el-option>
                  <el-option v-for="(item, index) in streetList" :key="index" :label="item.name"
                             :value="item.kid"></el-option>
                </el-select>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-input v-model="currentDetail.goodsReturnAddressDetail" placeholder="请输入详细地址"></el-input>
        </el-row>
        <el-row :gutter="1" style="margin-top: 10px">
          <el-col span="12">
            <el-input v-model="currentDetail.goodsReturnName" placeholder="请输入收件人姓名"></el-input>
          </el-col>
          <el-col span="12" >
            <el-input v-model="currentDetail.goodsReturnTel" placeholder="请输入收件人电话"></el-input>
          </el-col>
        </el-row>
        </el-form>

        <div v-if="currentDetail.expressNo" style="display:flex;margin-top: 10px">
          <div>商品已寄回：</div>
          <div>{{currentDetail.expressName + ' ' + currentDetail.expressNo}}</div>
        </div>
      </el-card>
      <el-card v-if="nextStatus===80">
        <div>驳回原因：</div>
        <el-input v-model="currentDetail.reply" type="textarea"  placeholder="请说明驳回原因" :autosize="{minRows:2}"></el-input>
      </el-card>
      <el-row style="display:flex;justify-content: space-around;margin-top: 10px">
        <el-button @click="cancelSubmit">取消</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </el-row>

    </el-dialog>
  </div>
</template>
<script>

import {getAfsListApi, getOrderAfsDetailApi, submitAfsUpdateStatusApi} from "@/api/lxh/applet/afterSale";
import uploadImg from "@/views/Shop/component/uploadImg.vue";
import {getJdRegionList} from "@/api/jdRegionApi";
import {getJdRegionListApi} from "@/api/lxh/applet/addressApi";

  export default {
    components: {uploadImg},
    data(){
      return{
        aa:"",
        createDateRange:"",
        //日期选择器 用
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        queryParams:{
          orderNo:"",
          createDateStart: null,
          createDateEnd: null,
          status:"",
          current:1,
          size:10
        },
        rows:[],
        totalCount:0,
        afsDetailVisible:false,
        currentDetail:{
          goodsInfo:{},
          id:"",
          typeDesc:"",
          questionDetail:"",
          imgUrlList:[],
          statusDesc:"",
          status:"",
          submitApplyStatus:"",
          goodsReturnProvinceId:"",
          goodsReturnCityId:"",
          goodsReturnAreaId:"",
          goodsReturnStreetId:"",
          goodsReturnAddressDetail:"",
          goodsReturnName:"",
          goodsReturnTel:"",
          expressName:"",
          expressNo:"",
          reply:"",
        },
        nextStatus:0,
        nextStatusDesc:"",
        orderAfsStatus: [
          { code: 5, description: "客户已提交"},
          { code: 10, description: "申请中待审核" },
          { code: 20, description: "审核完成待收货" },
          { code: 30, description: "收货完成待处理；" },
          { code: 40, description: "处理完成（如需退款则等待退款）" },
          { code: 50, description: "待用户确认" },
          { code: 60, description: "用户确认完成" },
          { code: 70, description: "取消" },
          { code: 80, description: "驳回" }
        ],
        provinceList: [],
        cityList: [],
        areaList: [],
        streetList: [],
      }
    },
    computed:{
    },
    methods:{
      init(){
          this.resetGetAfsList();
      },
      startDateChange(param) {
        if (Array.isArray(param)) {
          console.log("afa")
          this.queryParams.createDateStart = param[0]
          this.queryParams.createDateEnd = param[1]
        } else {
          this.queryParams.createDateStart = null
          this.queryParams.createDateEnd = null
        }
      },
      async getAfsList() {
        if(this.queryParams.status=="0"){
          this.queryParams.status = null
        }
        let { data } = await getAfsListApi(this.queryParams)
        this.rows = data.rows
        this.totalCount = data.total
      },
      //重置分页参数，获取list
      resetGetAfsList() {
        this.queryParams.current = 1;
        this.getAfsList();
      },
      handleCurrentChange(param) {
        this.queryParams.current = param
        this.getAfsList();
      },
      handleSizeChange(param) {
        this.queryParams.current = 1
        this.queryParams.size = param
        this.getAfsList();
      },
      openThisAfsDetail(item){
        this.afsDetailVisible = true
        this.getAfsDetail(item.id)
      },
      async getAfsDetail(id){
        let {data} = await getOrderAfsDetailApi({id})
        this.currentDetail = data
        this.nextStatus = data.status

        if(this.currentDetail.goodsReturnProvinceId){
          const regionQuery = {};
          regionQuery.pId = this.currentDetail.goodsReturnProvinceId
          const {data} = await getJdRegionList(regionQuery);
          this.cityList = data
        }

        if(this.currentDetail.goodsReturnCityId){
          const regionQuery = {};
          regionQuery.pId = this.currentDetail.goodsReturnCityId
          const {data} = await getJdRegionList(regionQuery);
          this.areaList = data
        }

        if(this.currentDetail.goodsReturnAreaId){
          const regionQuery = {};
          regionQuery.pId = this.currentDetail.goodsReturnAreaId
          const {data} = await getJdRegionList(regionQuery);
          this.streetList = data
        }


        this.getCurrentStatusDesc()
      },
      getCurrentStatusDesc(){
        let currentStatus = this.nextStatus
        this.orderAfsStatus.forEach(item=>{
          if(item.code === currentStatus){
              if(currentStatus===10 && this.currentDetail.goodsInfo.goodsType==1){
                this.nextStatusDesc = "提交售后申请到京东"
              }else{
                this.nextStatusDesc = item.description

              }
          }
        })
      },
      handleCommand(command) {
        this.nextStatus = command*1
        this.getCurrentStatusDesc()
      },
      async getProvinceRegionList() {
        const regionQuery = {};
        regionQuery.pId = 0
        const {data} = await getJdRegionListApi(regionQuery);
        this.provinceList = data
      },
      async provinceChange(kid) {
        if(kid==null || kid==""){
          this.cityList = []
          this.areaList = []
          this.streetList=[]
          this.currentDetail.goodsReturnCityId = ""
          this.currentDetail.goodsReturnAreaId = ""
          this.currentDetail.goodsReturnStreetId = ""
          return
        }
        const regionQuery = {};
        regionQuery.pId = kid
        const {data} = await getJdRegionList(regionQuery);
        this.cityList = data
        this.areaList = []
        this.streetList=[]
        this.currentDetail.goodsReturnCityId = ""
        this.currentDetail.goodsReturnAreaId = ""
        this.currentDetail.goodsReturnStreetId = ""
      },
      async cityChange(kid) {
        const regionQuery = {};
        regionQuery.pId = kid
        const {data} = await getJdRegionList(regionQuery);
        this.areaList = data
        this.streetList=[]
        this.currentDetail.goodsReturnAreaId = ""
        this.currentDetail.goodsReturnStreetId = ""
      },
      async areaChange(kid){
        const regionQuery = {};
        regionQuery.pId = kid
        const {data} = await getJdRegionList(regionQuery);
        this.streetList = data
        this.currentDetail.goodsReturnStreetId = ""
      },
      cancelSubmit(){
        this.afsDetailVisible = false
      },
      async submit(){

        if(this.nextStatus==20){
          if(this.currentDetail.goodsReturnProvinceId==""
              || this.currentDetail.goodsReturnCityId==""
              || this.currentDetail.goodsReturnAreaId==""
              || this.currentDetail.goodsReturnAddressDetail==""
              || this.currentDetail.goodsReturnName==""
              || this.currentDetail.goodsReturnTel==""){
            this.$message({
              message: "请完善退货地址信息",
              type: "warning",
            });
            return
          }
        }
        if(this.nextStatus===80){
          if(!this.currentDetail.reply || !this.currentDetail.reply.trim()){
            this.$message({
              message: "请输入驳回原因",
              type: "warning",
            })
            return
          }
        }

        let param = {
          id:this.currentDetail.id,
          status:this.nextStatus,
          goodsReturnProvinceId:this.currentDetail.goodsReturnProvinceId,
          goodsReturnCityId:this.currentDetail.goodsReturnCityId,
          goodsReturnAreaId:this.currentDetail.goodsReturnAreaId,
          goodsReturnStreetId:this.currentDetail.goodsReturnStreetId,
          goodsReturnAddressDetail:this.currentDetail.goodsReturnAddressDetail,
          goodsReturnName:this.currentDetail.goodsReturnName,
          goodsReturnTel:this.currentDetail.goodsReturnTel,
          reply:this.currentDetail.reply
        }

        let {data} = await submitAfsUpdateStatusApi(param)
        if(data.code==200){
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.afsDetailVisible = false
          await this.resetGetAfsList()
        }else{
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }


      }

    },

    created() {
      // this.getAfsList();
      this.getProvinceRegionList()
    }
  }
</script>